import React, { useEffect, useState } from 'react'
import { appConstants } from 'utils/constants.js'
import { Row, Col, Button, ListGroup } from 'react-bootstrap'
import axios from 'axios'
import I18n from 'i18n-js/locales.js'
import ImageGallery from 'react-image-gallery'
import Iframe from '../details/Iframe'

export default function CollectionDetails(props) {
  const [collection, setCollection] = useState({})
  const [collectionResources, setCollectionResources] = useState([])
  const [collectionPhotos, setCollectionPhotos] = useState([])
  const [virtualTour, setVirtualTour] = useState("")
  const [show, setShow] = useState(props.show)

  const loadCollection = async() => {
    if (show) {
      const res = await axios.get(`${appConstants.COLLECTIONS_URL}${props.id}.json`)
      setCollection(res.data)
      setCollectionResources(res.data["resources"])
      setVirtualTour(res.data["virtual_tour"])
      setCollectionResources(res.data["resources"])
      setCollectionPhotos(res.data["photos"].map(i => {
        const g = {}
        g["original"] = i.url
        g["description"] = i.name
        return g
      }))
    }
  }

  useEffect(() => {
    loadCollection()
  }, [props.id, props.show])

  const openResource = (r) => {
    setShow(false);
    if (props.openResource) {
      props.openResource(r)
    } else {
      return window.location.href = r.direct_link
    }
  }

  const renderEditButton = (c) => {
    return c.can_edit ? (
      <Button variant="outline-primary" href={`${appConstants.COLLECTIONS_URL}${props.id}/edit`}>{I18n.t('navigation.edit')}</Button>
    ) : ""
  }

  const renderDeleteButton = (c) => {
    return c.can_delete ? (
      <Button variant="outline-danger" href={`${appConstants.COLLECTIONS_URL}${props.id}/delete`}>{I18n.t('navigation.delete')}</Button>
    ) : ""
  }

  return (
    <>
      <Row>
        <Col md={12}>
          <h5>{collection.description}</h5>
        </Col>
        <Col md={12}>
          {virtualTour ? <Iframe source={virtualTour.url} height={'600px'} width={'100%'} /> : ''}
        </Col>
      </Row>
      <Row>
        <Col md={collectionPhotos.length > 0 ? 4 : 12}>
          <Row>
            <Col md={12}>
              <br/>
              <b>{I18n.t('activerecord.attributes.collection.resources')}</b>
              <ListGroup variant="flush">
                {collectionResources.map((r) => <ListGroup.Item key={r.id} action onClick={() => openResource(r, props.id)}>{r.name}</ListGroup.Item>)}
              </ListGroup>
            </Col>
          </Row>
        </Col>
        <Col md={collectionPhotos.length > 0 ? 8 : 0}>
          {collectionPhotos.length > 0 ? <ImageGallery showThumbnails={false} items={collectionPhotos} /> : ''}
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <hr/>
        </Col>
      </Row>
      <Row>
        <Col md={10}>
          {renderEditButton(collection)} 
        </Col>
        <Col md={2}>
          {renderDeleteButton(collection)}
        </Col>
      </Row>
    </>
  )
}
