import React from 'react'
import I18n from 'i18n-js/locales.js'
import { appConstants } from 'utils/constants.js'
import MonitoringRequestInfo from './MonitoringRequestInfo.js'
import Gallery from '../galleries/Gallery.js'
import SimpleList from '../lists/SimpleList.js'
import { Container, Row, Col, Accordion } from 'react-bootstrap'

export default function MonitoringRequestDetails(props) {
  return (
    <Container>
        <Col>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                  {I18n.t('activerecord.attributes.monitoring_request.details')}
              </Accordion.Header>
              <Accordion.Body>
                <Row>
                  <Col md={6}>
                    <MonitoringRequestInfo
                      monitoringRequestId={props.monitoringRequest.id} />
                  </Col>
                  <Col md={6}>
                    <Gallery
                      url={appConstants.IMAGES_FOR_MONITORING_REQUEST_URL + String(props.monitoringRequest.id)}
                      title={I18n.t('activerecord.attributes.monitoring_request.site_visit_images')} />
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                {I18n.t('activerecord.attributes.monitoring_request.resources')}
              </Accordion.Header>
              <Accordion.Body>
                <SimpleList
                  url={appConstants.RESOURCES_FOR_MONITORING_REQUEST_URL + String(props.monitoringRequest.id)}
                  title={I18n.t('activerecord.attributes.monitoring_request.resources')} />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
    </Container>
  )
}