import React, { useEffect, useState }  from "react";
import axios from 'axios'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster';
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import styled from 'styled-components'
import { chartColors } from 'utils/constants.js'
import '@fortawesome/fontawesome-free/css/all.min.css'
import '@fortawesome/fontawesome-free/js/all.min.js'
import { BsBoxArrowDown, BsBoxArrowUp } from "react-icons/bs";
import { Collapse, Form } from 'react-bootstrap'
import I18n from 'i18n-js/locales.js'

const centerPoint = [6.16, -66.4]

const StyledMapContainer = styled(MapContainer)`
  height: 800px;
  `;

const LegendColorBox = styled.div`
  float: left;
  height: 1em;
  width: 1em;
  margin-top: 2px;
  border: 1px solid black;
  clear: both;
  `;

const Legend = styled.div`
  opacity: .8;
  background-color: gainsboro;
  color:black;
  height: auto;
  width: auto;
  margin: 1em;
  border-radius: .5em;
  position: absolute;
  padding: .5em;
  bottom: 0em;
  max-height: 18em;
  overflow: ${props =>
    props.showLegend ? "auto" : ""}
  `;

const LegendButton = styled.div`
  z-index: 600;
  font-size: small;
  cursor: pointer;
  color: #205492;
  `;

const MapOptions = styled.div`
  right: .5em;
  position: absolute;
  z-index: 500;
  background-color: gainsboro;
  opacity: .8;
  color:black;
  margin: 1em;
  border-radius: .5em;
  padding: .5em;
  font-size: small;
  `;

export default function EventMap(props) {

  const [values, setValues] = useState({})
  const [showLegend, setShowLegend] = useState(true)
  const [mapClusters, setMapClusters] = useState(true)

  const getValues = async () => {
    let legend_items = {}
    if (props.legendItems) {
      legend_items = props.legendItems
    } else {
      const res = props.url ? await axios.get(props.url) : {"data": []}
      res.data.forEach((f, i) => {
        legend_items[f.id] = {name: f.name, color: chartColors[i]}
      })
    }
    setValues(legend_items)
  }

  useEffect(() => {
    getValues()
  }, [])

  const renderMapOptions = () => {
    return (
      <MapOptions>
        <Form.Check
          inline
          label={I18n.t('headers.map.show_clusters')}
          type="checkbox"
          name="clusters"
          checked={mapClusters}
          value={true}
          onChange={() => {setMapClusters(!mapClusters)}}
          id="1" />
      </MapOptions>
    )
  }

  const renderLegend = () => {
    return (
      <Legend className="leaflet-control" showLegend={showLegend}>
        <LegendButton
          onClick={() => {setShowLegend(!showLegend)}}
          aria-controls="collapse-legend"
          aria-expanded={showLegend}>
            <h4>{showLegend ? <BsBoxArrowDown /> : <BsBoxArrowUp />}</h4>
        </LegendButton>
        <Collapse in={showLegend}>
          <div id="collapse-legend">
            {Object.keys(values).map(key =>
              <div key={key}><LegendColorBox style={{backgroundColor: values[key].color}}></LegendColorBox>&nbsp;{values[key].name}</div>
            )}
          </div>
        </Collapse>
      </Legend>
    )
  }

  const singleColorClusters = (cluster) => {
    return (
      L.divIcon({ 
        html: '<div><span><b>' + cluster.getChildCount() + '</b></span></div>', 
        className: 'marker-cluster marker-cluster-medium', 
        iconSize: new L.Point(40,40) })
    )
  }

  const renderIcon = (id, virtual) => {
    const icon = virtual ? "fa-phone fa-2x" : "fa-map-pin fa-3x"
    const pinHtml = values[id] ? `<i class="fa ${icon}" style="color: ${values[id].color} "></i>` : `<i class="fa ${icon}"></i>`
    return (
        L.divIcon({
          html: pinHtml,
          className: ''
      }) 
    )
  }

  const renderPins = () => {
    return (
      props.data.map(v =>  {
        if (v.longitude && v.latitude && v.longitude != "0.0" && v.latitude != "0.0") {
          const filterValue = isNaN(v[props.filter_id]) ? v[props.filter_id] : Math.round(parseFloat(v[props.filter_id]))
          return (
            <Marker
              position={[parseFloat(v.latitude), parseFloat(v.longitude)]}
              key={v.id}
              icon={renderIcon(filterValue, v.virtual)}>
              <Popup>{props.markerContent(v)}</Popup>
            </Marker>
          )
        }
      })
    )
  }

  const renderClusters = () => {
    return (
      mapClusters ?
        <MarkerClusterGroup key={Math.random()} iconCreateFunction={singleColorClusters} showCoverageOnHover={false}>
          { renderPins()}
        </MarkerClusterGroup>
      : <>{ renderPins()}</>
    )
  }
  
  return (
    <StyledMapContainer center={centerPoint} zoom={6} scrollWheelZoom={true}>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {renderMapOptions()}
      {renderClusters()}
      {renderLegend()}
    </StyledMapContainer>
  )
}

