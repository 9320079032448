import React from 'react'
import axios from 'axios'
import I18n from 'i18n-js/locales.js'
import { Form, Row, Col, Button, Alert } from 'react-bootstrap'
import SelectSingle from '../selects/SelectSingle.js'
import { appConstants } from 'utils/constants.js'
import { formatValidationErrors } from 'utils/format.js'
import { SingleDatePicker } from 'react-dates'
import moment from 'moment'

export default class FlagForm extends React.Component {
  state = {
    id: this.props.flag.id || "",
    name: this.props.flag.name || "",
    description: this.props.flag.description || "",
    flag_priority: this.props.flag.flag_priority || "", 
    monitoring_request_id: this.props.flag.monitoring_request_id || "",
    due_date: this.props.flag.due_date ? moment(this.props.due_date) : null,
    user_id: this.props.flag.user_id || "",
    flag_status: this.props.flag.flag_status || "",
    due_focused: false,
  }

  createFlagData = () => {
    let formData = new FormData()
    formData.append('flag[name]', this.state.name)
    formData.append('flag[description]',  this.state.description)
    formData.append('flag[flag_priority]', this.state.flag_priority) 
    formData.append('flag[monitoring_request_id]', this.state.monitoring_request_id)
    formData.append('flag[due_date]', this.state.due_date)
    formData.append('flag[user_id]', this.state.user_id)
    formData.append('flag[flag_status]', this.state.flag_status)

    return formData
  }

  handleSubmit = () => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = this.props.authenticityToken;
    const flag = this.createFlagData()
    if (this.state.id) {
      axios.put(`${appConstants.FLAGS_URL}/${this.state.id}`, flag )
      .then(res => {
        this.setState({ alertMessage: I18n.t("notices.update_success") });
        this.props.refreshData()
      })
      .catch(error => {
        console.log(error);
        this.setState({ alertMessage: formatValidationErrors('flag', error.response.data) });
      })
    } else {
      axios.post(appConstants.FLAGS_URL, flag )
      .then(res => {
        this.setState({ alertMessage: I18n.t("notices.create_success") });
        this.setState({id: res.data.id})
        this.props.refreshData()
      })
      .catch(error => {
        console.log(error);
        this.setState({ alertMessage: formatValidationErrors('flag', error.response.data) });
      })
    }
  }

  handleDelete = () => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = this.props.authenticityToken;
    axios.delete(`${appConstants.FLAGS_URL}/${this.state.id}.json`)
    .then(res => {
      this.setState({ alertMessage: I18n.t("notices.delete_success") });
      this.props.closeModal()
      this.props.refreshData()
    })
    .catch(error => {
      console.log(error)
      this.setState({ alertMessage: I18n.t("notices.delete_failure") });
    })
  }

  handleAlertMessage = () => {
    if (this.state.alertMessage){
      return (
        <Alert variant={'info'} onClose={() => this.setState({ alertMessage: null })} dismissible>
          {this.state.alertMessage}
        </Alert>
      )
    } else {
      return ""
    }
  };

  handleInputChange = (e) => {
    const target = e.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value
    })
  }

  handleSelectChange = (e, selectName) => {
    let selectValue = null
    if (e) { selectValue = e.value } 
    this.setState({
      [selectName]: selectValue
    })
  }

  renderDeleteButton() {
    if (this.props.canEdit) {
      return (<Button variant="outline-danger" style={{float: 'right'}} onClick={this.handleDelete}>{I18n.t("navigation.delete")}</Button>)
    }
  }

  renderAddUpdateButton() {
    if (this.props.canEdit) {
      return (<Button variant="outline-primary" onClick={this.handleSubmit}>{this.state.id ? I18n.t("navigation.update") : I18n.t("navigation.add")}</Button>)
    }
  }

  render() {
    return (
      <Form>
        <Row>
          <Col>
            {this.handleAlertMessage()}
          </Col>
        </Row>

        <Row>
          <Form.Group as={Col} md={12} className="mb-3" controlId="flagForm.name">
            <Form.Label>{I18n.t('activerecord.attributes.flag.name')}<span className="text-danger">*</span></Form.Label>
            <Form.Control
              name="name"
              type="text"
              value={this.state.name}
              onChange={this.handleInputChange} />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} md={6} className="mb-3">
            <Form.Group controlId="flagForm.monitoring_request" className="mb-3">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.monitoring_event')}<span className="text-danger">*</span></Form.Label>
              <SelectSingle
                selected={this.state.monitoring_request_id}
                onValueChange={(e) => this.handleSelectChange(e, "monitoring_request_id")}
                url={appConstants.FLAGGABLE_REQUESTS_URL}
                isClearable={false}
                placeholder={I18n.t('selects.single')} />
            </Form.Group>
            <Form.Group controlId="flagForm.priority" className="mb-3">
              <Form.Label>{I18n.t('activerecord.attributes.flag.flag_priority')}<span className="text-danger">*</span></Form.Label>
              <SelectSingle
                selected={this.state.flag_priority}
                onValueChange={(e) => this.handleSelectChange(e, "flag_priority")}
                url={appConstants.FLAG_PRIORITIES_URL}
                isClearable={false}
                placeholder={I18n.t('selects.single')} />
            </Form.Group>
          </Form.Group>
          <Form.Group as={Col} md={6} className="mb-3" controlId="flagForm.description">
            <Form.Label>{I18n.t('activerecord.attributes.flag.description')}</Form.Label>
            <Form.Control
              name="description"
              as="textarea"
              value={this.state.description}
              onChange={this.handleInputChange}
              rows="4" />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} md={2} className="mb-3">
            <Form.Label>{I18n.t('activerecord.attributes.flag.due_date')}</Form.Label><br/>
            <SingleDatePicker
              date={this.state.due_date}
              onDateChange={due_date => this.setState({ due_date })}
              focused={this.state.due_focused}
              onFocusChange={({ focused: due_focused }) => this.setState({ due_focused })}
              id="due_date"
              isOutsideRange={()=>false} />
          </Form.Group>
          <Form.Group as={Col} md={4} className="mb-3">
            <Form.Label>{I18n.t('activerecord.attributes.flag.flag_status')}</Form.Label>
            <SelectSingle
              selected={this.state.flag_status}
              onValueChange={(e) => this.handleSelectChange(e, "flag_status")}
              url={appConstants.FLAG_STATUSES_URL}
              isClearable={false}
              placeholder={I18n.t('selects.single')} />
          </Form.Group>
          <Form.Group as={Col} md={6} className="mb-3">
            <Form.Label>{I18n.t('activerecord.attributes.flag.assignee')}</Form.Label>
            <SelectSingle
              selected={this.state.user_id}
              onValueChange={(e) => this.handleSelectChange(e, "user_id")}
              url={appConstants.USAID_USERS_URL}
              isClearable={true}
              placeholder={I18n.t('selects.single')} />
          </Form.Group>
        </Row>

        <Form.Group className="mb-3" controlId="flagForm.buttons">
          {this.renderAddUpdateButton()} &nbsp;
          {this.renderDeleteButton()}
        </Form.Group>
      </Form>
    );
  };
}