import React, { useState, useEffect } from 'react'
import axios from 'axios'
import I18n from 'i18n-js/locales.js'
import { Form, Row, Col, Button, Alert, Tab, Tabs, Card, ListGroup } from 'react-bootstrap'
import SelectSingle from '../selects/SelectSingle.js'
import SelectMultiple from '../selects/SelectMultiple.js'
import SelectMonthYear from '../selects/SelectMonthYear.js'
import ZoneForm from './ZoneForm.js'
import { SingleDatePicker } from 'react-dates'
import moment from 'moment'
import { appConstants } from 'utils/constants.js'
import { BiPlus, BiLinkExternal } from 'react-icons/bi'

function lastMonth() {
  const date = new Date()
  date.setDate(17)
  return new Date(date.setMonth(date.getMonth() - 1))
}

export default function SiteVisitForm(props) {
  const [id, setId] = useState(props.id || '')
  const [description, setDescription] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [dateSubmitted, setDateSubmitted] = useState(null)
  const [reportingDate, setReportingDate] = useState(lastMonth())
  const [userId, setUserId] = useState('')
  const [activityId, setActivityId] = useState('')
  const [governorateId, setGovernorateId] = useState('')
  const [districtId, setDistrictId] = useState('')
  const [territoryId, setTerritoryId] = useState('')
  const [zoneId, setZoneId] = useState('')
  const [sectorId, setSectorId] = useState('')
  const [virtual, setVirtual] = useState(false)
  const [requestJustification, setRequestJustification] = useState('')
  const [requestJustificationDetails, setRequestJustificationDetails] = useState('')
  const [beneficiaries, setBeneficiaries] = useState('')
  const [subsectorIds, setSubsectorIds] = useState([])
  const [agreementIds, setAgreementIds] = useState([])
  const [ipPlanId, setIpPlanId] = useState('')
  const [startFocused, setStartFocused] = useState(false)
  const [endFocused, setEndFocused] = useState(false)
  const [submittedFocused, setSubmittedFocused] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [updated, setUpdated] = useState(false)
  const [deleted, setDeleted] = useState(false)
  const [showAddZoneModal, setShowAddZoneModal] = useState(false)
  const [createDuplicate, setCreateDuplicate] = useState(false)
  const [tpmLeader, setTpmLeader] = useState('')
  const [monitor1, setMonitor1] = useState('')
  const [monitor2, setMonitor2] = useState('')
  const [seaPoc, setSeaPoc] = useState('')
  const [visitStartTime, setVisitStartTime] = useState('')
  const [siteAddress, setSiteAddress] = useState('')
  const [beneficiaryDetails, setBeneficiaryDetails] = useState('')
  const [ipocDetails, setIpocDetails] = useState('')
  const [staffDetails, setStaffDetails] = useState('')
  const [observation, setObservation] = useState('')
  const [visitApprovalDetails, setVisitApprovalDetails] = useState('')
  const [securityRiskDetails, setSecurityRiskDetails] = useState('')
  const [logisticalDetails, setLogisticalDetails] = useState('')
  const [logisticalPoc, setLogisticalPoc] = useState('')
  const [vehicleDetails, setVehicleDetails] = useState('')
  const [visitApproved, setVisitApproved] = useState(false)
  const [tabKey, setTabKey] = useState('sitevisit')
  const [fulcrumData, setFulcrumData] = useState({})

  useEffect(() => {
    if (tabKey === "fulcrum" && Object.keys(fulcrumData).length === 0) {
      const fetchData = async () => {
        const response = await axios.get(`${appConstants.FORMS_FOR_VISIT_URL}/${props.id}`)
        setFulcrumData(response.data || {})
      }
      fetchData()
    }
  }, [tabKey])

  useEffect(() => {
    if (updated) {
      props.refreshData()
      setUpdated(false)
    }
    if (deleted) {
      props.refreshData()
      setDeleted(false)
      props.closeModal()
    }
  }, [updated, deleted])

  useEffect(() => {
    if (createDuplicate && !id) {
      setCreateDuplicate(false)
      handleSubmit()
    }
  }, [createDuplicate])

  useEffect(() => {
    if (props.id) {
      const fetchData = async () => {
        const response = await axios.get(`${appConstants.MONITORING_EVENT_URL}/${props.id}.json`)
        setDescription(response.data.data.description || '')
        setStartDate(response.data.start_date ? moment(response.data.start_date) : null)
        setEndDate(response.data.end_date ? moment(response.data.end_date) : null)
        setDateSubmitted(response.data.date_submitted ? moment(response.data.date_submitted) : null)
        setReportingDate(response.data.reporting_date ? new Date(response.data.reporting_date) : lastMonth())
        setUserId(response.data.user_id || '')
        setActivityId(response.data.activity_id || '')
        setGovernorateId(response.data.governorate.id || '')
        setDistrictId(response.data.district.id || '')
        setTerritoryId(response.data.territory.id || '')
        setZoneId(response.data.zone_id || '')
        setSectorId(response.data.sector_id || '')
        setVirtual(response.data.virtual || false)
        setRequestJustification(response.data.request_justification || '')
        setRequestJustificationDetails(response.data.data.request_justification_details || '')
        setBeneficiaries(response.data.data.beneficiaries || '')
        setSubsectorIds(response.data.subsectors ? response.data.subsectors.map(s => s.id) : [])
        setAgreementIds(response.data.agreements ? response.data.agreements.map(a => a.id) : [])
        setIpPlanId(response.data.ip_plan_id || '')
        setTpmLeader(response.data.tpm_leader || '')
        setMonitor1(response.data.monitor_1 || '')
        setMonitor2(response.data.monitor_2 || '')
        setSeaPoc(response.data.sea_poc || '')
        setVisitStartTime(response.data.visit_start_time || '')
        setSiteAddress(response.data.site_address || '')
        setBeneficiaryDetails(response.data.beneficiary_details || '')
        setIpocDetails(response.data.ipoc_details || '')
        setStaffDetails(response.data.staff_details || '')
        setObservation(response.data.observation || false)
        setVisitApprovalDetails(response.data.visit_approval_details || '')
        setSecurityRiskDetails(response.data.security_risk_details || '')
        setLogisticalDetails(response.data.logistical_details || '')
        setLogisticalPoc(response.data.logistical_poc || '')
        setVehicleDetails(response.data.vehicle_details || '')
        setVisitApproved(response.data.visit_approved || false)
      }

      fetchData()
    }
  }, [props.id])

  

  const createFormData = function()  {
    let formData = new FormData()
    formData.append('monitoring_request[description]',  description)
    formData.append('monitoring_request[start_date]', startDate) 
    formData.append('monitoring_request[end_date]', endDate)
    formData.append('monitoring_request[date_submitted]', dateSubmitted)
    formData.append('monitoring_request[beneficiaries]', beneficiaries)
    formData.append('monitoring_request[request_justification]', requestJustification)
    formData.append('monitoring_request[request_justification_details]', requestJustificationDetails)
    formData.append('monitoring_request[virtual]', virtual || false)
    formData.append('monitoring_request[user_id]', userId || "")
    formData.append('monitoring_request[activity_id]', activityId || "")
    formData.append('monitoring_request[district_id]', districtId || "")
    formData.append('monitoring_request[territory_id]', territoryId || "")
    formData.append('monitoring_request[zone_id]', zoneId || "")
    formData.append('monitoring_request[sector_id]', sectorId || "")
    formData.append('monitoring_request[ip_plan_id]', ipPlanId || "")
    formData.append('monitoring_request[reporting_date]', reportingDate || "")
    formData.append('monitoring_request[tpm_leader]', tpmLeader || "")
    formData.append('monitoring_request[monitor_1]', monitor1 || "")
    formData.append('monitoring_request[monitor_2]', monitor2 || "")
    formData.append('monitoring_request[sea_poc]', seaPoc || "")
    formData.append('monitoring_request[visit_start_time]', visitStartTime || "")
    formData.append('monitoring_request[site_address]', siteAddress || "")
    formData.append('monitoring_request[beneficiary_details]', beneficiaryDetails || "")
    formData.append('monitoring_request[ipoc_details]', ipocDetails || "")
    formData.append('monitoring_request[staff_details]', staffDetails || "")
    formData.append('monitoring_request[observation]', observation || false)
    formData.append('monitoring_request[visit_approval_details]', visitApprovalDetails || "")
    formData.append('monitoring_request[security_risk_details]', securityRiskDetails || "")
    formData.append('monitoring_request[logistical_details]', logisticalDetails || "")
    formData.append('monitoring_request[logistical_poc]', logisticalPoc || "")
    formData.append('monitoring_request[vehicle_details]', vehicleDetails || "")
    formData.append('monitoring_request[visit_approved]', visitApproved || false)
    subsectorIds.forEach(s => {
      formData.append('monitoring_request[subsector_ids][]', s)
    })
    agreementIds.forEach(a => {
      formData.append('monitoring_request[agreement_ids][]', a)
    })

    return formData
  }

  const handleSubmit = async () => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = props.authenticityToken;
    const data = createFormData()
    const successNotice = id ? I18n.t("notices.update_success") : I18n.t("notices.create_success")
    const res = id ? await axios.put(`${appConstants.MONITORING_EVENT_URL}${id}`, data ) : await axios.post(appConstants.MONITORING_EVENT_URL, data )
    const notice = res.status === 200 ? successNotice : I18n.t("notices.failure")
    setAlertMessage(notice)
    setUpdated(true)
  }

  const handleDelete = async () => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = props.authenticityToken;
    const res = await axios.delete(`${appConstants.MONITORING_EVENT_URL}/${id}.json`)
    const notice = res.status === 204 ? I18n.t("notices.delete_success") : I18n.t("notices.delete_failure")
    setAlertMessage(notice)
    setDeleted(true)
  }

  const handleReportingDateChange = (e) => {
    let d = reportingDate || new Date()
    d.setDate(17)

    if (e.field=="year") {
      d.setYear(e.value)
    } else {
      d.setMonth(e.value, 17)
    }

    setReportingDate(d)
  }

  const renderAlertMessage = () => {
    return alertMessage ? (
      <Alert variant={'info'} onClose={() => setAlertMessage("")} dismissible>
        {alertMessage}
      </Alert>
    ) : ""
  }

  const renderAddZoneButton = () => {
    return territoryId && props.canEdit ? (
      <Button variant="outline-secondary" onClick={() => setShowAddZoneModal(true)} className="add-collection-btn" title={I18n.t('zone.add')}><BiPlus /></Button>
    ) : ""
  }

  const closeAddZoneModal = (z) => {
    setShowAddZoneModal(false)
    setZoneId(z)
    setAlertMessage("")
  }

  const renderDuplicateButton = () => {
    if (id) {
      return (<Button disabled={!props.canEdit} variant="outline-primary" onClick={() => {setId(""); setCreateDuplicate(true)}}>{I18n.t("navigation.duplicate")}</Button> )
    }
  }

  const renderDeleteButton = () => {
    return (<Button disabled={!props.canEdit} variant="outline-danger" style={{float: 'right'}} onClick={() => handleDelete()}>{I18n.t("navigation.delete")}</Button>)
  }


  return showAddZoneModal ? (
    <ZoneForm
        authenticityToken={props.authenticityToken}
        zone={{territory_id: territoryId}}
        isModal={true}
        closeModal={closeAddZoneModal} />
  ) : (
    <Form>
      <Row>
        <Col>
          {renderAlertMessage()}
        </Col>
      </Row>
      <Tabs activeKey={tabKey} id="sitevisit-tabs" onSelect={(k) => setTabKey(k)}>
        <Tab eventKey="sitevisit" title={I18n.t('navigation.site_visit_tab')} className="mb-3">
          <Row className="mt-3">
            <Form.Group as={Col} md={3} controlId="requestForm.activity">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.activity')}</Form.Label>
              <SelectSingle
                isDisabled={!props.canEdit}
                selected={activityId}
                onValueChange={(e) => setActivityId(e ? e.value : "")}
                url={appConstants.ACTIVITIES_URL + ".json"}
                isClearable={true}
                placeholder={I18n.t('selects.single')} />
            </Form.Group>

            <Form.Group as={Col} md={3} className="mb-3" controlId="requestForm.sector">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.sector')}</Form.Label>
              <SelectSingle
                isDisabled={!props.canEdit}
                selected={sectorId}
                onValueChange={(e) => setSectorId(e ? e.value : "")}
                url={appConstants.SECTORS_URL}
                placeholder={I18n.t('selects.single')}
                isClearable={true} />
            </Form.Group>

            <Form.Group as={Col} md={6} className="mb-3" controlId="requestForm.subsector">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.subsectors')}</Form.Label>
              <SelectMultiple
                isDisabled={!props.canEdit}
                selected={subsectorIds}
                onChange={(e) => setSubsectorIds(e ? e.map(i => i.value) : [])}
                url={appConstants.SUBSECTORS_URL + "?sector_id=" + (sectorId || -1)}
                placeholder={I18n.t('selects.multiple_optional')}
                groupedName='subsectors'
                isDependent={true} />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md={3} className="mb-3" controlId="requestForm.governorate">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.governorate')}</Form.Label>
              <SelectSingle
                isDisabled={!props.canEdit}
                selected={governorateId}
                onValueChange={(e) => {
                  setGovernorateId(e ? e.value : "")
                  setDistrictId("")
                  setTerritoryId("")
                  setZoneId("")
                }}
                url={appConstants.GOVERNORATES_URL}
                isClearable={true}
                placeholder={I18n.t('selects.single')} />
            </Form.Group>

            <Form.Group as={Col} md={3} className="mb-3" controlId="requestForm.district">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.district')}</Form.Label>
              <SelectSingle
                isDisabled={!props.canEdit}
                selected={districtId}
                onValueChange={(e) => {
                  setDistrictId(e ? e.value : "")
                  setTerritoryId("")
                  setZoneId("")
                }}
                url={appConstants.DISTRICTS_BY_GOVERNORATE_URL + (governorateId || -1) + ".json"}
                placeholder={I18n.t('selects.single')}
                isClearable={true}
                isDependent={true} />
            </Form.Group>

            <Form.Group as={Col} md={3} className="mb-3" controlId="requestForm.territory">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.territory')}</Form.Label>
              <SelectSingle
                isDisabled={!props.canEdit}
                selected={territoryId}
                onValueChange={(e) => {
                  setTerritoryId(e ? e.value : "")
                  setZoneId("")
                }}
                url={appConstants.TERRITORIES_BY_DISTRICT_URL + (districtId || -1) + ".json"}
                placeholder={I18n.t('selects.single')}
                isClearable={true}
                isDependent={true} />
            </Form.Group>

            <Form.Group as={Col} md={3} className="mb-3" controlId="requestForm.zone">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.zone')}</Form.Label> &nbsp;
              {renderAddZoneButton()}
              <SelectSingle
                isDisabled={!props.canEdit}
                selected={zoneId}
                onValueChange={(e) => setZoneId(e ? e.value : "")}
                url={appConstants.ZONES_BY_TERRITORY_URL + (territoryId || -1) + ".json"}
                placeholder={I18n.t('selects.single')}
                isClearable={true}
                isDependent={true} />
            </Form.Group>
          </Row>
          
          <Row>
            <Form.Group as={Col} md={10} className="mb-3" controlId="requestForm.site_address">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.site_address')}</Form.Label>
                <Form.Control 
                  disabled={!props.canEdit}
                  name="siteAddress"
                  type="text"
                  value={siteAddress}
                  onChange={(e) => setSiteAddress(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md={2} className="mb-3" controlId="requestForm.virtual">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.virtual')}</Form.Label>
              <Form.Check
                disabled={!props.canEdit}
                type="switch"
                name="virtual"
                checked={virtual}
                value={true}
                onChange={() => setVirtual(!virtual)}
                bsPrefix="virtual-switch" />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md={6} className="mb-3" controlId="requestForm.ip_plan">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.ip_plan')}</Form.Label>
              <SelectSingle
                isDisabled={!props.canEdit}
                selected={ipPlanId}
                onValueChange={(e) => setIpPlanId(e ? e.value : "")}
                url={appConstants.IP_PLANS_URL}
                isClearable={true}
                placeholder={I18n.t('selects.single')} />
            </Form.Group>

            <Form.Group as={Col} md={6} className="mb-3" controlId="requestForm.reporting_date">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.reporting_date')}</Form.Label>
              <SelectMonthYear
                isDisabled={!props.canEdit}
                onChange={(e) => handleReportingDateChange(e)}
                myDate={reportingDate} />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md={4} className="mb-3" controlId="requestForm.sea_poc">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.sea_poc')}</Form.Label>
                <Form.Control 
                  disabled={!props.canEdit}
                  name="seaPoc"
                  type="text"
                  value={seaPoc}
                  onChange={(e) => setSeaPoc(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md={2} className="mb-3" controlId="requestForm.visit_start_time">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.visit_start_time')}</Form.Label>
                <Form.Control 
                  disabled={!props.canEdit}
                  name="visitStartTime"
                  type="text"
                  value={visitStartTime}
                  onChange={(e) => setVisitStartTime(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md={2} className="mb-3" controlId="requestForm.start_date">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.start_date')}</Form.Label><br/>
              <SingleDatePicker
                disabled={!props.canEdit}
                date={startDate}// momentPropTypes.momentObj or null
                onDateChange={start_date => setStartDate( start_date )} // PropTypes.func.isRequired
                focused={startFocused} // PropTypes.bool
                onFocusChange={({ focused: start_focused }) => setStartFocused( start_focused )} // PropTypes.func.isRequired
                id="start_date" // PropTypes.string.isRequired,
                isOutsideRange={()=>false}
              />
            </Form.Group>
            <Form.Group as={Col} md={2} className="mb-3" controlId="requestForm.date_of_report">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.end_date')}</Form.Label><br/>
              <SingleDatePicker
                disabled={!props.canEdit}
                date={endDate}// momentPropTypes.momentObj or null
                onDateChange={end_date => setEndDate( end_date )} // PropTypes.func.isRequired
                focused={endFocused} // PropTypes.bool
                onFocusChange={({ focused: end_focused }) => setEndFocused( end_focused )} // PropTypes.func.isRequired
                id="date_of_report" // PropTypes.string.isRequired,
                isOutsideRange={()=>false}
              />
            </Form.Group>

            <Form.Group as={Col} md={2} className="mb-3" controlId="requestForm.date_submitted">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.date_submitted')}</Form.Label><br/>
              <SingleDatePicker
                disabled={!props.canEdit}
                date={dateSubmitted}// momentPropTypes.momentObj or null
                onDateChange={date_submitted => setDateSubmitted( date_submitted )} // PropTypes.func.isRequired
                focused={submittedFocused} // PropTypes.bool
                onFocusChange={({ focused: submitted_focused }) => setSubmittedFocused( submitted_focused )} // PropTypes.func.isRequired
                id="date_submitted" // PropTypes.string.isRequired,
                isOutsideRange={()=>false}
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} className="mb-3" md={12} controlId="requestForm.beneficiaries">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.beneficiaries')}</Form.Label>
              <Form.Control
                disabled={!props.canEdit}
                as="textarea"
                name="beneficiaries"
                value={beneficiaries}
                onChange={(e) => setBeneficiaries(e.target.value)}
                rows="1" />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} className="mb-3" md={6} controlId="requestForm.description">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.description')}</Form.Label>
              <Form.Control
                disabled={!props.canEdit}
                as="textarea"
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder={I18n.t('activerecord.attributes.monitoring_request.description_placeholder')}
                rows="2" />
            </Form.Group>

            <Form.Group as={Col} className="mb-3" md={6} controlId="requestForm.beneficiary_details">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.beneficiary_details')}</Form.Label>
              <Form.Control
                disabled={!props.canEdit}
                as="textarea"
                name="beneficiaryDetails"
                value={beneficiaryDetails}
                onChange={(e) => setBeneficiaryDetails(e.target.value)}
                placeholder={I18n.t('activerecord.attributes.monitoring_request.beneficiary_placeholder')}
                rows="2" />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} className="mb-3" md={6} controlId="requestForm.ipoc_details">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.ipoc_details')}</Form.Label>
              <Form.Control
                disabled={!props.canEdit}
                as="textarea"
                name="ipocDetails"
                value={ipocDetails}
                onChange={(e) => setIpocDetails(e.target.value)}
                placeholder={I18n.t('activerecord.attributes.monitoring_request.ipoc_placeholder')}
                rows="2" />
            </Form.Group>

            <Form.Group as={Col} className="mb-3" md={6} controlId="requestForm.staff_details">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.staff_details')}</Form.Label>
              <Form.Control
                disabled={!props.canEdit}
                as="textarea"
                name="staffDetails"
                value={staffDetails}
                onChange={(e) => setStaffDetails(e.target.value)}
                placeholder={I18n.t('activerecord.attributes.monitoring_request.staff_placeholder')}
                rows="2" />
            </Form.Group>
          </Row>

          <Row>

            <Form.Group as={Col} md={2} className="mb-3" controlId="requestForm.observation">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.observation')}</Form.Label>
              <Form.Check
                disabled={!props.canEdit}
                type="switch"
                name="observation"
                checked={observation}
                value={true}
                onChange={() => setObservation(!observation)}
                bsPrefix="observation-switch" />
            </Form.Group>

            <Form.Group as={Col} md={4} className="mb-3" controlId="requestForm.visit_approval_details">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.visit_approval_details')}</Form.Label>
                <Form.Control 
                  disabled={!props.canEdit}
                  name="visitApprovalDetails"
                  type="text"
                  value={visitApprovalDetails}
                  onChange={(e) => setVisitApprovalDetails(e.target.value)} />
            </Form.Group>
    
            <Form.Group as={Col} md={3} className="mb-3" controlId="requestForm.postponed_reason">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.request_justification')}</Form.Label>
              <SelectSingle
                isDisabled={!props.canEdit}
                selected={requestJustification}
                onValueChange={(e) => setRequestJustification(e ? e.value : "")}
                url={appConstants.JUSTIFICATIONS_URL}
                isClearable={true}
                placeholder={I18n.t('selects.single')} />
            </Form.Group>

            <Form.Group as={Col} md={3} className="mb-3" controlId="requestForm.postponed_details">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.request_justification_details')}</Form.Label>
                <Form.Control 
                  disabled={!props.canEdit}
                  name="requestJustificationDetails"
                  type="text"
                  value={requestJustificationDetails}
                  onChange={(e) => setRequestJustificationDetails(e.target.value)} />
            </Form.Group>
            
          </Row>

          <Form.Group  className="mb-3" controlId="resourceForm.buttons">
            <Button disabled={!props.canEdit} variant="outline-primary" onClick={() => handleSubmit()}>{id ? I18n.t("navigation.update") : I18n.t("navigation.add")}</Button> &nbsp;
            {renderDuplicateButton()} &nbsp;
            {renderDeleteButton()}
          </Form.Group>
        </Tab>
        <Tab eventKey="coordinator" title={I18n.t('navigation.coordination_tab')}>
          <Row className="mt-3">
            <Form.Group as={Col} md={6} className="mb-3" controlId="requestForm.user">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.user')}</Form.Label>
              <SelectSingle
                isDisabled={!props.canEdit && !props.canCoordinate}
                selected={userId}
                onValueChange={(e) => setUserId(e ? e.value : "")}
                url={appConstants.MONITORS_URL}
                isClearable={true}
                placeholder={I18n.t('selects.single')} />
            </Form.Group>

            <Form.Group as={Col} md={6} className="mb-3" controlId="requestForm.tpm_leader">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.tpm_leader')}</Form.Label>
                <Form.Control 
                  disabled={!props.canEdit && !props.canCoordinate}
                  name="tpmLeader"
                  type="text"
                  value={tpmLeader}
                  onChange={(e) => setTpmLeader(e.target.value)} />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md={6} className="mb-3" controlId="requestForm.monitor_1">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.monitor_1')}</Form.Label>
                <Form.Control 
                  disabled={!props.canEdit && !props.canCoordinate}
                  name="monitor1"
                  type="text"
                  value={monitor1}
                  onChange={(e) => setMonitor1(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md={6} className="mb-3" controlId="requestForm.monitor_2">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.monitor_2')}</Form.Label>
                <Form.Control 
                  disabled={!props.canEdit && !props.canCoordinate}
                  name="monitor2"
                  type="text"
                  value={monitor2}
                  onChange={(e) => setMonitor2(e.target.value)} />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} className="mb-3" md={6} controlId="requestForm.security_risk_details">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.security_risk_details')}</Form.Label>
              <Form.Control
                disabled={!props.canEdit && !props.canCoordinate}
                as="textarea"
                name="securityRiskDetails"
                value={securityRiskDetails}
                onChange={(e) => setSecurityRiskDetails(e.target.value)}
                rows="2" />
            </Form.Group>

            <Form.Group as={Col} className="mb-3" md={6} controlId="requestForm.logistical_details">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.logistical_details')}</Form.Label>
              <Form.Control
                disabled={!props.canEdit && !props.canCoordinate}
                as="textarea"
                name="logisticalDetails"
                value={logisticalDetails}
                onChange={(e) => setLogisticalDetails(e.target.value)}
                rows="2" />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} className="mb-3" md={6} controlId="requestForm.logistical_poc">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.logistical_poc')}</Form.Label>
              <Form.Control
                disabled={!props.canEdit && !props.canCoordinate}
                as="textarea"
                name="logisticalPoc"
                value={logisticalPoc}
                onChange={(e) => setLogisticalPoc(e.target.value)}
                rows="2" />
            </Form.Group>

            <Form.Group as={Col} className="mb-3" md={6} controlId="requestForm.vehicle_details">
              <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.vehicle_details')}</Form.Label>
              <Form.Control
                disabled={!props.canEdit && !props.canCoordinate}
                as="textarea"
                name="vehicleDetails"
                value={vehicleDetails}
                onChange={(e) => setVehicleDetails(e.target.value)}
                rows="2" />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md={12} className="mb-3" controlId="requestForm.visit_approved">
              <Card className="m-3 p-3" border={visitApproved ? "success" : "danger"}>
                <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.visit_approved')}</Form.Label>
                <Form.Check
                  disabled={!props.canEdit && !props.canCoordinate}
                  type="switch"
                  name="visitApproved"
                  checked={visitApproved}
                  value={true}
                  onChange={() => setVisitApproved(!visitApproved)}
                  bsPrefix="visitApproved-switch" />
                </Card>
            </Form.Group>

          </Row>

          <Form.Group  className="mb-3" controlId="resourceForm.buttons">
            <Button disabled={!props.canEdit && !props.canCoordinate} variant="outline-primary" onClick={() => handleSubmit()}>{id ? I18n.t("navigation.update") : I18n.t("navigation.add")}</Button> &nbsp;
          </Form.Group>

        </Tab>

        <Tab eventKey="fulcrum" title={I18n.t('navigation.fulcrum_tab')} className="mb-3">
          <Row className="mt-3">
            { Object.keys(fulcrumData).map(k =>
                <Card key={k} className="mt-3">
                  <Card.Body>
                    <Card.Title>{fulcrumData[k].name} <a href={appConstants.FULCRUM_URL + "apps/" + k} target="_blank" rel="noopener noreferrer"><BiLinkExternal /></a></Card.Title>
                    <ListGroup variant="flush">
                      { fulcrumData[k].records.map(r =>
                          <ListGroup.Item key={r}><a href={appConstants.FULCRUM_URL + "records/" + r} target="_blank" rel="noopener noreferrer">{ r }</a></ListGroup.Item>
                        )
                      }
                    </ListGroup>
                  </Card.Body>
                </Card>
              )
            }
          </Row>
        </Tab>
      </Tabs>
    </Form>
  )
}