import React from 'react'
import axios from 'axios'
import I18n from 'i18n-js/locales.js'
import { Form, Row, Col, Button, Alert, InputGroup } from 'react-bootstrap'
import SelectSingle from '../selects/SelectSingle.js'
import SelectMultiple from '../selects/SelectMultiple.js'
import SelectMonthYear from '../selects/SelectMonthYear.js'
import SimpleList from '../lists/SimpleList.js'
import { SingleDatePicker } from 'react-dates'
import { BiReset } from "react-icons/bi";
import moment from 'moment'
import { appConstants } from 'utils/constants.js'
import { uploadFile } from 'utils/directUpload.js'
// to use local storage for files, comment out line above, and uncomment the line below
// import { uploadFile } from 'utils/localUpload.js'
import { formatValidationErrors } from 'utils/format.js'

export default class IpPlanForm extends React.Component {
  state = {
    id: this.props.ipPlanId || "",
    activityId: "",
    ipPlanStatus: "discussion",
    poc: "",
    description: "",
    postponedReason: "",
    generalInfo: "",
    assigneeId: "",
    officeId: "",
    sectorId: "",
    tagIds: [],
    agreementIds: [],
    subsectorIds: [],
    initialFocused: false,
    requestedFocused: false,
    providedFocused: false,
    proposedFocused: false,
    securityPlanFilename: "",
    securityPlanFileUrl: "",
    showSecurityPlanInput: false,
    assignedRound: null,
    isLoading: true,
  }

  loadPlan = async() => {
    axios.get(`${appConstants.IP_PLAN_URL}${this.props.ipPlanId}.json`)
    .then(res => {
      this.setState({
        activityId: res.data.activity_id,
        ipPlanStatus: res.data.ip_plan_status,
        startDate: null,
        endDate: null,
        initialMeetingDate: res.data.initial_meeting_date ? moment(res.data.initial_meeting_date) : null,
        sitesRequestedDate: res.data.sites_requested_date ? moment(res.data.sites_requested_date) : null,
        sitesProvidedDate: res.data.sites_provided_date ? moment(res.data.sites_provided_date) : null,
        sitesProposedDate: res.data.sites_proposed_date ? moment(res.data.sites_proposed_date) : null,
        poc: res.data.poc || "",
        description: res.data.description || "",
        postponedReason: res.data.postponed_reason || "",
        generalInfo: res.data.general_info || "",
        assigneeId: res.data.assignee_id || "",
        officeId: res.data.office_id || "",
        sectorId: res.data.sector_id || "",
        tagIds: [],
        agreementIds: res.data.agreement_ids || [],
        subsectorIds: res.data.subsector_ids || [],
        initialFocused: false,
        requestedFocused: false,
        providedFocused: false,
        proposedFocused: false,
        securityPlanFilename: res.data.security_plan_filename || "",
        securityPlanFileUrl: res.data.security_plan_file_url || "",
        assignedRound: res.data.assigned_round ? new Date(res.data.assigned_round) : new Date(),
        isLoading: false,
      //  ...res.data,
      })
    })
    .catch(error => {
      console.log(error);
    })
  }

  componentDidMount = () => {
    this.loadPlan()
  }

  createPlanData = async function()  {
    let formData = new FormData()
    formData.append('ip_plan[activity_id]', this.state.activityId)
    formData.append('ip_plan[ip_plan_status]', this.state.ipPlanStatus)
    formData.append('ip_plan[start_date]', this.state.startDate)
    formData.append('ip_plan[end_date]', this.state.endDate)
    formData.append('ip_plan[initial_meeting_date]', this.state.initialMeetingDate)
    formData.append('ip_plan[sites_requested_date]', this.state.sitesRequestedDate)
    formData.append('ip_plan[sites_provided_date]', this.state.sitesProvidedDate)
    formData.append('ip_plan[sites_proposed_date]', this.state.sitesProposedDate)
    formData.append('ip_plan[poc]', this.state.poc)
    formData.append('ip_plan[description]', this.state.description)
    formData.append('ip_plan[general_info]', this.state.generalInfo)
    formData.append('ip_plan[postponed_reason]', this.state.postponedReason)
    formData.append('ip_plan[user_id]', this.state.assigneeId || "")
    formData.append('ip_plan[office_id]', this.state.officeId || "")
    formData.append('ip_plan[sector_id]', this.state.sectorId || "")
    formData.append('ip_plan[assigned_round]', this.state.assignedRound)
    this.state.tagIds.forEach(tagId => {
      formData.append('ip_plan[tag_ids][]', tagId)
    })
    this.state.agreementIds.forEach(agreementId => {
      formData.append('ip_plan[agreement_ids][]', agreementId)
    })
    this.state.subsectorIds.forEach(subsectorId => {
      formData.append('ip_plan[subsector_ids][]', subsectorId)
    })
    if (this.state.security_plan_file) {
      const blobIdOrFile = await uploadFile(this.state.security_plan_file)
      formData.append('ip_plan[security_plan_file]', blobIdOrFile)
    }

    return formData
  }

  handleSubmit = () => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = this.props.authenticityToken;
    if (this.state.id) {
      this.createPlanData().then((ip_plan) => {
        axios.put(`${appConstants.IP_PLAN_URL}${this.state.id}`, ip_plan )
        .then(res => {
          this.setState({ alertMessage: I18n.t("notices.update_success") },()=>{
            window.setTimeout(()=>{
              this.setState({alertMessage:null})
            },2000)
          });
          this.props.refreshData()
        })
        .catch(error => {
          console.log(error);
          this.setState({ alertMessage: formatValidationErrors('ip_plan', error.response.data) });
        })
      })
    } else {
      this.createPlanData().then((ip_plan) => {
        axios.post(appConstants.IP_PLAN_URL, ip_plan )
        .then(res => {
          this.setState({ alertMessage: I18n.t("notices.create_success") });
          this.setState({id: res.data.id})
          this.props.refreshData()
        })
        .catch(error => {
          console.log(error);
          this.setState({ alertMessage: formatValidationErrors('ip_plan', error.response.data) });
        })
      })
    }
  }

  handleDelete = () => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = this.props.authenticityToken;
    axios.delete(`${appConstants.IP_PLAN_URL}${this.state.id}.json`)
    .then(res => {
      this.setState({ alertMessage: I18n.t("notices.delete_success") });
      this.props.closeModal()
      this.props.refreshData()
    })
    .catch(error => {
      console.log(error)
      this.setState({ alertMessage: I18n.t("notices.delete_failure") });
    })
  }

  handleAssignedRoundChange = (e) => {
    let d = this.state.assignedRound || new Date()
    d.setDate(17)

    if (e.field=="year") {
      d.setYear(e.value)
    } else {
      d.setMonth(e.value, 17)
    }
    this.setState({assignedRound: d })
  }

  handleFileSelect = (e) => {
    this.setState({blobIdOrFile: "" })
    this.setState({security_plan_file: e.target.files[0]})
    this.setState({securityPlanFilename: e.target.files[0].name})
  }

  handleFileClear = (e) => {
    this.setState({blobIdOrFile: "" })
    this.setState({security_plan_file: null})
    this.setState({securityPlanFilename: ""})
  }

  handleAlertMessage = () => {
    if (this.state.alertMessage){
      return (
        <Alert variant={'info'} onClose={() => this.setState({ alertMessage: null })} dismissible>
          {this.state.alertMessage}
        </Alert>
      )
    } else {
      return ""
    }
  }

  handleInputChange = (e) => {
    const target = e.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value
    })
  }

  handleCheckbox = (e) => {
    const target = e.target
    const name = target.name
    const value = !this.state[name]
    this.setState({
      [name]: value
    })
  }

  handleMultiChange = (e, field) => {
    const ids = e != null ? e.map(i => i.value) : []
    this.setState({
      [field]: ids
    }, () => this.updateDependentSelected(field))
  }

  handleSelectChange = (e, selectName) => {
    let selectValue = null
    if (e) { selectValue = e.value } 
    this.setState({
      [selectName]: selectValue
    }, () => this.updateDependentSelected(selectName))
  }

  updateDependentSelected = (selectName) => {
    if (selectName === 'sectorId') {
      this.setState({ subsectorIds: [] })
    }
  }

  renderResourceList() {
    if (this.state.id) {
      return (
        <SimpleList
          url={appConstants.RESOURCES_FOR_IP_PLAN_URL + String(this.state.id)}
          title={I18n.t('activerecord.attributes.ip_plan.resources')} />
      )
    }
  }

  renderSiteVisitsList() {
    if (this.state.id) {
      return (
        <div>
          <SimpleList
            url={appConstants.IP_PLAN_VISITS_URL + String(this.state.id)}
            title={I18n.t('activerecord.attributes.ip_plan.monitoring_requests')} />
        </div>
      )
    }
  }

  renderSecurityPlanInput() {
    return (
      <>
        <InputGroup>
          <Form.Control
            type="file"
            disabled={!(this.props.canCoordinate || this.props.canEdit)}
            onChange={this.handleFileSelect}
            placeholder={this.state.securityPlanFilename || I18n.t('resource.upload_file')} />
          <Button variant="outline-secondary" onClick={this.handleFileClear}><BiReset /></Button>
        </InputGroup>
      </>
    )
  }

  renderSecurityPlan() {
    return this.state.securityPlanFilename && !this.state.showSecurityPlanInput ? (
      <>
        <Form.Group>
          <a href={this.state.securityPlanFileUrl} target="_blank" rel="noopener noreferrer">{this.state.securityPlanFilename}</a>
        </Form.Group>
      </>
    ) : this.renderSecurityPlanInput()
  }

  renderSecurityPlanToggleButton() {
    return this.state.securityPlanFilename ? (
      <Button variant="secondary" onClick={()=>{this.setState({showSecurityPlanInput: !this.state.showSecurityPlanInput})}}>{this.state.showSecurityPlanInput && this.state.securityPlanFilename ? 'Cancel' : 'Change'}</Button>
    ) : ""
  }

  renderSecurityPlanContainer() {
    return (
      <>
        <Form.Label>{I18n.t('activerecord.attributes.ip_plan.security_plan')}</Form.Label>&nbsp;<span>{this.state.showSecurityPlanInput && this.state.securityPlanFilename ? `(${this.state.securityPlanFilename})` : ""}</span>
        <Row>
          <Col md={this.state.securityPlanFilename ? 9 : 12}>
            {this.renderSecurityPlan()}
          </Col>
          <Col md={this.state.securityPlanFilename ? 3 : 0}>
            {this.renderSecurityPlanToggleButton()}
          </Col>
        </Row>
      </>
    )
  }

  render() {
    return !this.state.isLoading ? (
      <Form>
        <Row>
          <Col>
            {this.handleAlertMessage()}
          </Col>
        </Row>

        <Row>
          <Form.Group as={Col} md={6} className="mb-3" controlId="planForm.activity">
            <Form.Label>{I18n.t('activerecord.attributes.ip_plan.activity')}<span className="text-danger">*</span></Form.Label>
            <SelectSingle
              isDisabled={!this.props.canEdit}
              selected={this.state.activityId}
              onValueChange={(e) => this.handleSelectChange(e, "activityId")}
              url={appConstants.ACTIVITIES_URL + ".json"}
              isClearable={true}
              placeholder={I18n.t('selects.single')} />
          </Form.Group>
          <Form.Group as={Col} md={6} className="mb-3" controlId="planForm.status">
            <Form.Label>{I18n.t('activerecord.attributes.ip_plan.ip_plan_status')}</Form.Label>
            <SelectSingle
              isDisabled={!this.props.canEdit}
              selected={this.state.ipPlanStatus}
              onValueChange={(e) => this.handleSelectChange(e, "ipPlanStatus")}
              url={appConstants.IP_PLAN_STATUSES_URL}
              isClearable={false} />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} md={6} className="mb-3" controlId="planForm.office">
            <Form.Label>{I18n.t('activerecord.attributes.ip_plan.office')}</Form.Label>
            <SelectSingle
              isDisabled={!this.props.canEdit}
              selected={this.state.officeId}
              onValueChange={(e) => this.handleSelectChange(e, "officeId")}
              url={appConstants.OFFICES_URL}
              isClearable={true}
              placeholder={I18n.t('selects.single')} />
          </Form.Group>
          <Form.Group as={Col} md={6} className="mb-3" controlId="planForm.user">
            <Form.Label>{I18n.t('activerecord.attributes.ip_plan.user')}</Form.Label>
            <SelectSingle
              isDisabled={!this.props.canEdit}
              selected={this.state.assigneeId}
              onValueChange={(e) => this.handleSelectChange(e, "assigneeId")}
              url={appConstants.ADMINS_URL}
              isClearable={true}
              placeholder={I18n.t('selects.single')} />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} md={4} className="mb-3" controlId="planForm.sector">
            <Form.Label>{I18n.t('activerecord.attributes.ip_plan.sector')}</Form.Label>
            <SelectSingle
              isDisabled={!this.props.canEdit}
              selected={this.state.sectorId}
              onValueChange={(e) => this.handleSelectChange(e, "sectorId")}
              url={appConstants.SECTORS_URL}
              placeholder={I18n.t('selects.single')}
              isClearable={true} />
          </Form.Group>
          <Form.Group as={Col} md={8} className="mb-3" controlId="planForm.subsector">
            <Form.Label>{I18n.t('activerecord.attributes.ip_plan.subsectors')}</Form.Label>
            <SelectMultiple
              isDisabled={!this.props.canEdit}
              selected={this.state.subsectorIds}
              onChange={(e) => this.handleMultiChange(e, 'subsectorIds')}
              url={appConstants.SUBSECTORS_URL + "?sector_id=" + (this.state.sectorId || -1)}
              placeholder={I18n.t('selects.multiple_optional')}
              groupedName='subsectors'
              isDependent={true} />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} md={12} className="mb-3" controlId="planForm.generalInfo">
            <Form.Label>{I18n.t('activerecord.attributes.ip_plan.site_visits_general_info')}</Form.Label><br/>
            <Form.Control
              disabled={!this.props.canEdit}
              as="textarea"
              name="generalInfo"
              value={this.state.generalInfo}
              onChange={this.handleInputChange}
              rows="2" />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} md={2} className="mb-3" controlId="planForm.sites_requested_date">
            <Form.Label>{I18n.t('activerecord.attributes.ip_plan.sites_requested')}</Form.Label><br/>
            <SingleDatePicker
              disabled={!this.props.canEdit}
              date={this.state.sitesRequestedDate}// momentPropTypes.momentObj or null
              onDateChange={sitesRequestedDate => this.setState({ sitesRequestedDate })} // PropTypes.func.isRequired
              focused={this.state.requestedFocused} // PropTypes.bool
              onFocusChange={({ focused: requestedFocused }) => this.setState({ requestedFocused })} // PropTypes.func.isRequired
              id="sites_requested_date" // PropTypes.string.isRequired,
              isOutsideRange={()=>false}
            />
          </Form.Group>
          <Form.Group as={Col} md={2} className="mb-3" controlId="planForm.sites_provided_date">
            <Form.Label>{I18n.t('activerecord.attributes.ip_plan.sites_provided')}</Form.Label><br/>
            <SingleDatePicker
              disabled={!this.props.canEdit}
              date={this.state.sitesProvidedDate}// momentPropTypes.momentObj or null
              onDateChange={sitesProvidedDate => this.setState({ sitesProvidedDate })} // PropTypes.func.isRequired
              focused={this.state.providedFocused} // PropTypes.bool
              onFocusChange={({ focused: providedFocused }) => this.setState({ providedFocused })} // PropTypes.func.isRequired
              id="sites_provided_date" // PropTypes.string.isRequired,
              isOutsideRange={()=>false}
            />
          </Form.Group>
          <Form.Group as={Col} md={2} className="mb-3" controlId="planForm.sites_proposed_date">
            <Form.Label>{I18n.t('activerecord.attributes.ip_plan.sites_proposed')}</Form.Label><br/>
            <SingleDatePicker
              disabled={!this.props.canEdit}
              date={this.state.sitesProposedDate}// momentPropTypes.momentObj or null
              onDateChange={sitesProposedDate => this.setState({ sitesProposedDate })} // PropTypes.func.isRequired
              focused={this.state.proposedFocused} // PropTypes.bool
              onFocusChange={({ focused: proposedFocused }) => this.setState({ proposedFocused })} // PropTypes.func.isRequired
              id="sites_proposed_date" // PropTypes.string.isRequired,
              isOutsideRange={()=>false}
            />
          </Form.Group>
          <Form.Group as={Col} md={2} className="mb-3" controlId="planForm.initial_meeting_date">
            <Form.Label>{I18n.t('activerecord.attributes.ip_plan.initial_meeting')}</Form.Label><br/>
            <SingleDatePicker
              disabled={!this.props.canEdit}
              date={this.state.initialMeetingDate}// momentPropTypes.momentObj or null
              onDateChange={initialMeetingDate => this.setState({ initialMeetingDate })} // PropTypes.func.isRequired
              focused={this.state.initialFocused} // PropTypes.bool
              onFocusChange={({ focused: initialFocused }) => this.setState({ initialFocused })} // PropTypes.func.isRequired
              id="initial_meeting_date" // PropTypes.string.isRequired,
              isOutsideRange={()=>false}
            />
          </Form.Group>

          <Form.Group as={Col} md={4} className="mb-3" controlId="planForm.assignedRound">
            <Form.Label>{I18n.t('activerecord.attributes.ip_plan.assigned_round')}</Form.Label><br/>
              <SelectMonthYear
                isDisabled={!this.props.canEdit}
                onChange={(e) => this.handleAssignedRoundChange(e)}
                myDate={this.state.assignedRound} />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} className="mb-3" md={3} controlId="planForm.description">
            <Form.Label>{I18n.t('activerecord.attributes.ip_plan.description')}</Form.Label>
            <Form.Control
              disabled={!this.props.canEdit}
              as="textarea"
              name="description"
              value={this.state.description}
              onChange={this.handleInputChange}
              rows="5" />
          </Form.Group>
          <Form.Group as={Col} md={3} className="mb-3">
            <Form.Group as={Col} md={12} className="mb-3" controlId="planForm.poc">
              <Form.Label>{I18n.t('activerecord.attributes.ip_plan.poc')}</Form.Label>
              <Form.Control
                disabled={!this.props.canEdit}
                name="poc"
                type="text"
                value={this.state.poc}
                onChange={this.handleInputChange} />
            </Form.Group>
            <Form.Group as={Col} className="mb-3" md={12} controlId="planForm.postponed_reason">
              <Form.Label>{I18n.t('activerecord.attributes.ip_plan.postponed_reason')}</Form.Label>
              <Form.Control
                disabled={!this.props.canEdit}
                name="postponedReason"
                type="text"
                value={this.state.postponedReason}
                onChange={this.handleInputChange} />
            </Form.Group>
          </Form.Group>
          <Form.Group as={Col} md={6}>
            <Row>
              <Form.Group as={Col} className="mb-3" md={12} controlId="planForm.file">
                {this.renderSecurityPlanContainer()}
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} className="mb-3" md={6}>
                {this.renderResourceList()}
              </Form.Group>
              <Form.Group as={Col} className="mb-3" md={6}>
                {this.renderSiteVisitsList()}
              </Form.Group>
            </Row>
          </Form.Group>
        </Row>

        <Form.Group  className="mb-3" controlId="planForm.buttons">
          <Button variant="outline-primary" disabled={!(this.props.canEdit || this.props.canCoordinate)} onClick={this.handleSubmit}>{this.state.id ? I18n.t("navigation.update") : I18n.t("navigation.add")}</Button> &nbsp;
          <Button variant="outline-danger" disabled={!this.props.canEdit} style={{float: 'right'}} onClick={this.handleDelete}>{I18n.t("navigation.delete")}</Button>
        </Form.Group>

      </Form>
    ) : ""
  }
}