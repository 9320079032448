import React, { Component }  from 'react'
import styled from 'styled-components'
import SortableTable from './tables/SortableTable.js'
import MonitoringRequestFilters from './filters/MonitoringRequestFilters'
import MonitoringRequestDetails from './details/MonitoringRequestDetails.js'
import FilterCounts from './filters/FilterCounts'
import { appConstants, setRequest } from 'utils/constants.js'
import SimpleList from './lists/SimpleList.js'
import I18n from 'i18n-js/locales.js'
import { CSVLink } from 'react-csv'
import { Form, Modal, Collapse, Tabs, Tab, Col, Row } from 'react-bootstrap'
import { BsBoxArrowDown, BsBoxArrowUp } from 'react-icons/bs'
import EventMap from './maps/EventMap.js'


const FiltersButton = styled.div`
  left: .5em;
  top: .1em;
  position: absolute;
  z-index: 0;
  cursor: pointer;
  color: #205492;
  `;

const ExportWrapper = styled(CSVLink)`
  left: 88%;
  top: -6px;
  position: relative;
  z-index: 0;
  `;

export default class TPMResults extends Component {

  state = {
    initialData: this.props.initialData.data || [],
    filteredData: this.props.initialData.data || [],
    filters: this.props.initialData.filters || [],
    tableColumns: this.props.initialData.columns || [],
    topLevelColumns: this.props.initialData.top_level_columns || [],
    viewModal: false,
    selectedRequest: {},
    showFilters: true,
    selectedTab: "summary",
  }

  // filtering functions
  handleFilterChange = (e, filterBy, clickClear) => {
    const filters = this.state.filters
    let filterValue = null
    if (e && (this.state.filters[filterBy] != e.value || !clickClear)) { filterValue = e.value } 
    const newFilters = {
      ...filters,
      [filterBy]: filterValue,
    }
    this.filterData(newFilters)
  }

  filterData = (filters) => {
    const data = this.state.initialData
    const filterKeys = Object.keys(filters)
    let filteredData = [...data]
    for (let filterKey of filterKeys) {
      if (filters[filterKey] !== null) {
        if (filters[filterKey] == -1) {
          filteredData = filteredData.filter(task => (task[filterKey] == "" || task[filterKey] == null) )
        } else {
          filteredData = filteredData.filter(task => task[filterKey] == filters[filterKey] )
        }
        
      }
    }
    
    const newState = {
      ...this.state,
      filteredData: filteredData,
      filters: filters,
    }
    this.setState(newState)
  }


  requestModalLink = (cell, row) => {
    return (
      cell["column"]["id"] === "name"
        ? <a href='#' onClick={() => {this.editRequest(row.original)}}>{row["original"]["name"]}</a>
        : cell.render('Cell') 
    )
  }

  // modal/form functions
  editRequest = (task) => {
    const request = setRequest(task)
    this.setState({
      selectedRequest: request,
      viewModal: true
    })
  }

  closeModal = () => {
    this.setState({
      viewModal: false
    })
  }

  taskContent = (task) => {
    const virtual = task.virtual ? <i className="fa fa-phone"></i> : ''
    return (
      <div>
        <strong><a href='#' onClick={() => {this.editRequest(task)}}>{task.id}. {task.territory_name} - {task.zone_name} - {task.start_date}</a></strong> {virtual}<br/>
        {I18n.t('activerecord.attributes.monitoring_request.name')}: {task.name} <br/>
        {I18n.t('activerecord.attributes.monitoring_request.act')}: {task.activity_name} <br/>
        {I18n.t('activerecord.attributes.monitoring_request.governorate')}: {task.governorate_name} <br/>
        {I18n.t('activerecord.attributes.monitoring_request.district')}: {task.district_name} <br/>
        {I18n.t('activerecord.attributes.monitoring_request.date_submitted')}: {task.date_submitted} <br/>
      </div>
    )
  }

  renderMonitoringRequestModalContent() {
    return (
      <MonitoringRequestDetails
        monitoringRequest={this.state.selectedRequest} />
    )
  }

  renderRequestModal() {
    return (
      <Modal
        show={this.state.viewModal}
        onHide={this.closeModal}
        keyboard={true}
        size={'xl'}>
        <Modal.Header closeButton>
        <Modal.Title>{I18n.t('activerecord.attributes.monitoring_request.monitoring_event')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderMonitoringRequestModalContent()}
        </Modal.Body>
      </Modal>
    )
  }

  renderFiltersButton() {
    return (
      <FiltersButton 
        onClick={() => this.setState({showFilters: !this.state.showFilters})} 
        aria-controls="collapse-filters" 
        aria-expanded={this.state.showFilters}>
          <h4>{this.state.showFilters ? <BsBoxArrowUp /> : <BsBoxArrowDown />}</h4>
      </FiltersButton>
    ) 
  }


  renderList() {
    return (this.state.selectedTab == "list") ?
      (
        <SortableTable
          data={this.state.filteredData}
          columns={this.state.tableColumns}
          useRenderFunction={true}
          cellContent={this.requestModalLink}
        />  
      ) : ""
  }

  renderDashboard() {
    return (this.state.selectedTab == "summary") ?
      (
      <>
        <Row>
          <Col md={3}>
            <FilterCounts
              filter_id={"governorate_id"}
              data={this.state.filteredData}
              url={appConstants.GOVERNORATES_URL}
              handleFilterChange={this.handleFilterChange}
              filters={this.state.filters}
              title={I18n.t('activerecord.attributes.monitoring_request.governorate')}
            />
            <FilterCounts
              filter_id={"implementing_partner_id"}
              data={this.state.filteredData}
              url={appConstants.IPS_URL}
              handleFilterChange={this.handleFilterChange}
              filters={this.state.filters}
              title={I18n.t('activerecord.attributes.monitoring_request.ip')}
              omit_empty={true}
            />
          </Col>
          <Col md={6}>
            {this.renderMap()}
          </Col>
          <Col md={3}>
            <SimpleList 
              url={appConstants.FILTERED_RESOURCES_URL}
              title={I18n.t('headers.resource.filtered_resources')}
              filters={this.state.filters} />
          </Col>
        </Row>
      </>
      ) : ""
  }

  renderMap() {
    return (
      <EventMap
        data={this.state.filteredData}
        markerContent={this.taskContent}
        url={appConstants.IPS_URL}
        filter_id={"implementing_partner_id"}
        show_clusters={this.state.mapClusters}
      />
      ) 
  }

  render() {
    return (
      <>
        {this.renderRequestModal()}
        <div>
          <Collapse in={this.state.showFilters}>
            <div id="collapse-filters">
              <MonitoringRequestFilters handleFilterChange={this.handleFilterChange} includedFilters={this.state.filters} excludeSearch={true} />
            </div>
          </Collapse>
          {this.renderFiltersButton()}
        </div>
        <div>
          <Tabs defaultActiveKey="summary" id="listTabs" onSelect={(selectedKey) => this.setState({selectedTab: selectedKey})}>
            <Tab eventKey="summary" title={I18n.t("headers.monitoring_request.results")}>
              <br/>
              {this.renderDashboard()}
            </Tab>
            <Tab eventKey="list" title={I18n.t("headers.monitoring_request.list")}>
              <br/>
              <ExportWrapper data={this.state.filteredData} filename={I18n.t("headers.monitoring_request.export_filename")}>{I18n.t("headers.monitoring_request.export")}</ExportWrapper>
              {this.renderList()}
            </Tab>
          </Tabs>
        </div>
      </>
    )
  }
}

