import React, { Component }  from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Card, Tabs, Tab, Modal, Button } from 'react-bootstrap'
import ResourceList from './lists/ResourceList'
import SearchBar from './filters/SearchBar'
import TagSelectList from './lists/TagSelectList'
import ResourceDetails from './details/ResourceDetails.js'
import CollectionDetails from './details/CollectionDetails.js'
import SimpleListForm from './lists/SimpleListForm'
import I18n from 'i18n-js/locales.js'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid';
import { appConstants, setResource } from 'utils/constants.js'
import styled from 'styled-components'

const NewResourceButton = styled(Button)`
  right: 1.5em;
  position: absolute;
  z-index: 0;
  `;

const NewCollectionButton = styled(Button)`
  right: 10em;
  position: absolute;
  z-index: 0;
  `;
export default class SearchPage extends Component {

  state = {
    tagIds: this.props.tagIds || [],
    searchTerms: this.props.searchTerms || "",
    showSavedSearches: false,
    savedSearches: [],
    selectedResource: {},
    selectedResourceCollection: {},
    viewResource: false,
    viewCollection: false,
  }

  componentDidMount() {
    this.loadSavedSearches()
  }

  handleSearch = () => {
    this.setState({
      searchTerms: document.getElementById('searchbar').value
    })
  }

  resetSearch = () => {
    this.setState({
      tagIds: [],
      searchTerms: ""
    })

    axios.defaults.headers.common["X-CSRF-TOKEN"] = this.props.authenticityToken;
    axios.put(`/kmp/search/reset`, {})
    .catch(error => {
      console.log(error.response.data);
    })
  }

  addTag = (e) => {
    const value = e.target.value;
    let newArray = this.state.tagIds.slice()
    newArray.push(value)
    
    this.setState({
      tagIds: newArray
    });
  }

  removeTag = (e) => {
    const value = Number(e.target.id);
    let newArray = this.state.tagIds.slice();
    newArray = newArray.filter(function(item) {
      return item !== value && item != null && item != ""
    })

    this.setState({
      tagIds: newArray
    });
  }

  handleSavedSearches = () => {
    const show = this.state.showSavedSearches;
    this.setState({
      showSavedSearches: !show
    })
  }

  loadSavedSearches= () => {
    return axios.get(`/kmp/searches`)
    .then(res => {
      const searches = res.data;
      this.setState({
        savedSearches: searches,
      })
    })
    .catch(error => {
      console.log(error)
    })
  }

  saveSearch = () => {
    const newSearch = {
      name: document.getElementById('new-list-item-name-input').value,
      tag_ids: this.state.tagIds,
      search_terms: this.state.searchTerms,
      uuid: uuidv4()
    }
    let searches = this.state.savedSearches.slice();
    searches.push(newSearch);

    this.setState({
      savedSearches: searches
    }, this.rewriteSearches)
  }

  deleteSearch = (search) => {
    const searches = this.state.savedSearches.filter(s => s.uuid !== search).slice();
    this.setState({
      savedSearches: searches
    }, this.rewriteSearches)
  }

  loadSearch = (s) => {
    this.setState({
      showSavedSearches: false,
      tagIds: s.tag_ids,
      searchTerms: s.search_terms
    })
    document.getElementById('searchbar').value = s.search_terms;
  }

  rewriteSearches = () =>  {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = this.props.authenticityToken;
    const postBody = {
      saved_searches: this.state.savedSearches
    }
    return axios.post(`/kmp/searches/all`, postBody)
  }

  hideSavedSearches = () => {
    this.setState({
      showSavedSearches: false
    })
  }

  showSaveForm = () => {
    return this.state.tagIds.length > 0
    || this.state.searchTerms.length > 0 ? true : false;
  }

  showResource = (r) => {
    const resource = setResource(r)
    this.setState({
      selectedResource: resource,
      viewResource: true,
      viewCollection: false,
    })
  }

  showCollection = (r) => {
    const resource = setResource(r)
    this.setState({
      selectedResource: resource,
      selectedResourceCollection: resource,
      viewResource: false,
      viewCollection: true,
    })
  }

  closeModal = () => {
    this.setState({
      viewResource: false,
      viewCollection: false,
      selectedResourceCollection: {},
    })
  }

  renderNewResourceButton() {
    return (this.props.canCreate) ?
    (
      <NewResourceButton variant="outline-primary" href={appConstants.NEW_RESOURCE_URL}>{I18n.t('headers.resource.new')}</NewResourceButton>
    ) : ""
  }

  renderNewCollectionButton() {
    return (this.props.canCreate) ?
    (
      <NewCollectionButton variant="outline-primary" href={appConstants.NEW_COLLECTION_URL}>{I18n.t('headers.collection.new')}</NewCollectionButton>
    ) : ""
  }

  renderSavedSearches() {
    return (
      <Modal
        show={this.state.showSavedSearches}
        onHide={this.hideSavedSearches}
        keyboard={true}
        backdrop={false}
        className="saved-searches-dropdown">
        <Modal.Header className="modal-header-no-border" closeButton>
          <Modal.Title>{I18n.t('search.saved_searches_modal_title')}</Modal.Title>
        </Modal.Header>
        <SimpleListForm
          showSaveForm={this.showSaveForm()}
          formPlaceholder={I18n.t("search.name_placeholder")}
          itemPlaceholder={I18n.t("search.none_saved")}
          listItems={this.state.savedSearches}
          saveItem={this.saveSearch}
          deleteItem={this.deleteSearch}
          selectItem={this.loadSearch} />
      </Modal>
    )
  }

  renderResourceModal() {
    return (
      <Modal
        show={this.state.viewResource}
        onHide={this.closeModal}
        keyboard={true}
        size={'xl'}>
        <Modal.Header closeButton>
        <Modal.Title>{`${this.state.selectedResource.name ? this.state.selectedResource.name : ""}` }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ResourceDetails id={this.state.selectedResource.id} show={this.state.viewResource} exitResource={this.closeModal} openCollection={this.showCollection} collection={this.state.selectedResourceCollection} />
        </Modal.Body>
      </Modal>
    )
  }

  renderCollectionModal() {
    return (
      <Modal
        show={this.state.viewCollection}
        onHide={this.closeModal}
        keyboard={true}
        size={'xl'}>
        <Modal.Header closeButton>
        <Modal.Title>{`${this.state.selectedResource.name ? this.state.selectedResource.name : ""}` }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CollectionDetails id={this.state.selectedResource.id} show={this.state.viewCollection} exitCollection={this.closeModal} openResource={this.showResource} />
        </Modal.Body>
      </Modal>
    )
  }

  render() {
    return (
      <Card body>
        {this.renderResourceModal()}
        {this.renderCollectionModal()}
        <Row>
          <Col>
            <SearchBar
                searchValue={this.state.searchTerms}
                handleChange={this.handleSearch}
                handleReset={this.resetSearch}
                handleSavedSearches={this.handleSavedSearches}
              /><br/>
            {this.renderSavedSearches()}
          </Col>
        </Row>
        <Row>
          <Col md={3} className="bg-light border-light shadow-sm">
            <Row>
              <Col>
                <TagSelectList
                  tagIds={this.state.tagIds}
                  searchTerms={this.state.searchTerms}
                  onTagAdd={this.addTag}
                  onTagRemove={this.removeTag} />
              </Col>
            </Row>
          </Col>
          <Col md={9} className="pl-3">
            {this.renderNewResourceButton()}
            {this.renderNewCollectionButton()}
            <Tabs defaultActiveKey="resources" id="listTabs" className="shadow-sm">
              <Tab eventKey="resources" title={I18n.t("headers.resource.list")} className="pt-3">
                <ResourceList
                  tagIds={this.state.tagIds}
                  searchTerms={this.state.searchTerms} 
                  target="resources"
                  setResource={this.showResource} />
              </Tab>
              <Tab eventKey="collections" title={I18n.t("headers.collection.list")} className="pt-3">
                <ResourceList
                  tagIds={this.state.tagIds}
                  searchTerms={this.state.searchTerms} 
                  target="collections"
                  setResource={this.showCollection} />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Card>
    );
  };
}

SearchPage.propTypes = {
  tagIds: PropTypes.array,
  searchTerms: PropTypes.string,
  authenticityToken: PropTypes.string,
};
