import React, { Component }  from 'react'
import axios from 'axios'
import styled from 'styled-components'
import SortableTable from './tables/SortableTable.js'
import I18n from 'i18n-js/locales.js'
import FlagForm from './forms/FlagForm.js'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { appConstants, setFlag, renderFlag } from 'utils/constants.js'

const NewFlagButton = styled(Button)`
  right: 1.5em;
  position: absolute;
  z-index: 0;
  `;

export default class TPMFlags extends Component {

  state = {
    initialData: this.props.initialData.data || [],
    filteredData: this.props.initialData.data || [],
    tableColumns: this.props.initialData.columns || [],
    viewModal: false,
    selectedFlag: {},
    selectedTab: "list",
  }

  refreshData = () => {
    axios.get(`${appConstants.FLAGS_URL}.json`)
    .then(res => {
      const flags = res.data;
      this.setState({
        filteredData: flags.data,
      })
    })
    .catch(error => {
      console.log(error);
    })
  }

  cellContent = (cell, row) => {
    return (
      cell["column"]["id"] === "name"
        ? (this.props.canEdit ? <a href='#' onClick={() => {this.editFlag(row.original)}}>{row["original"]["name"]}</a> : row["original"]["name"])
        : cell["column"]["id"] === 'flag_priority'
        ? <span id={Math.random()} title={`${row["original"]["flag_priority"]} priority`}> {renderFlag(row["original"]["flag_priority"])} </span>
        : cell.render('Cell')
    )
  }

  // modal/form functions
  editFlag = (f) => {
    const flag = setFlag(f)
    this.setState({
      viewModal: true,
      selectedFlag: flag
    })
  }

  newFlag = () => {
    this.setState({
      viewModal: true,
      selectedFlag: {}
    })
  }

  closeModal = () => {
    this.setState({
      viewModal: false
    })
  }

  renderFlagModalContent() {
    return (
      <FlagForm col={6}
        flag={this.state.selectedFlag}
        authenticityToken={this.props.authenticityToken}
        refreshData={this.refreshData}
        closeModal={this.closeModal}
        canEdit={this.props.canEdit} />
    )
  }

  renderFlagModal() {
    return this.props.canEdit ?
    (
      <Modal
        show={this.state.viewModal}
        onHide={this.closeModal}
        keyboard={true}
        size={'xl'}>
        <Modal.Header closeButton>
          <Modal.Title>{`${I18n.t('activerecord.attributes.flag.flag')} ${this.state.selectedFlag.id ? this.state.selectedFlag.id : ""}` }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderFlagModalContent()}
        </Modal.Body>
      </Modal>
    ) : ""
  }

  renderNewFlagButton() {
    return this.props.canEdit ?
    (
      <NewFlagButton variant="outline-primary" onClick={() => this.newFlag()}>{I18n.t('headers.flag.new')}</NewFlagButton>
    ) : ""
  }

  renderList() {
    return (this.state.selectedTab == "list") ?
      (
        <SortableTable
          data={this.state.filteredData}
          columns={this.state.tableColumns}
          useRenderFunction={true}
          cellContent={this.cellContent}
        />  
      ) : ""
  }

  render() {
    return (
      <>
        {this.renderFlagModal()}
        <div>
        {this.renderNewFlagButton()}
          <Tabs defaultActiveKey="list" id="listTabs" onSelect={(selectedKey) => this.setState({selectedTab: selectedKey})}>
            <Tab eventKey="list" title={I18n.t("headers.flag.list")}>
              <br/>
              {this.renderList()}
            </Tab>
          </Tabs>
        </div>
      </>
    )
  }
}

